import { Box, BoxProps, Stack, Tooltip, TooltipContent } from '@dtx-company/design-system/src'
import { FC, ReactNode, useState } from 'react'
import { IthacaOperationName } from '@dtx-company/true-common/src/types/operations'
import { LockedFeaturesUpsellContent } from '../../constants/lockedFeatures'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { ProTag } from '../../../../code/src/components/ProTag/ProTag'
import { ResponsiveStyleValue } from '@mui/system'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { getPlanTypeName } from '../../utils/getPlanTypeName'
import { setLockedFeatureModal } from '@dtx-company/inter-app/src/redux/slices/lockedFeatureModalSlice'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useDispatch } from 'react-redux'
import { useGetLockedFeaturePlanType } from '../../hooks/useGetLockedFeaturePlanType/useGetLockedFeaturePlanType'
import { useScreenSizeBreakpoints } from '../../hooks/useScreenSizeBreakpoints'
import { useTrialConfig } from '../../hooks/useTrialConfig/useTrialConfig'

type IconPosition = 'left' | 'right' | 'top' | 'bottom'
export interface LockedFeatureWrapperProps {
  children: ReactNode
  featureName: string
  shouldDisplayLockIcon?: boolean
  minimalIcon?: boolean
  showTooltip?: boolean
  lockIconCopy?: string
  iconPosition?: IconPosition
  iconSx?: BoxProps['sx']
  sx?: BoxProps['sx']
  onOpen?: () => void
}

const getFlexDirectionValue = (
  position: IconPosition
): ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'> => {
  switch (position) {
    case 'left':
      return 'row-reverse'
    case 'right':
      return 'row'
    case 'top':
      return 'column-reverse'
    case 'bottom':
      return 'column'
  }
}

export const LockedFeatureWrapper: FC<LockedFeatureWrapperProps> = ({
  featureName,
  children,
  shouldDisplayLockIcon = true,
  minimalIcon = false,
  showTooltip = true,
  lockIconCopy,
  iconPosition = 'right',
  iconSx,
  sx,
  onOpen
}) => {
  const dispatch = useDispatch()
  const { hasOperation } = useAuthState()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const { isMobile } = useScreenSizeBreakpoints()
  const lockedFeaturePlanType = useGetLockedFeaturePlanType(featureName)

  const trialConfig = useTrialConfig()
  const isInTrial = trialConfig?.inTrial

  const hasAccessToFeature =
    hasOperation(featureName as IthacaOperationName) || lockedFeaturePlanType === PlanType.FREE
  const showLockIcon = shouldDisplayLockIcon && !hasAccessToFeature
  const planTypeName = getPlanTypeName(lockedFeaturePlanType).toUpperCase()
  const lockContent = LockedFeaturesUpsellContent[featureName]
  const tooltipContent = lockContent?.tooltipContent
  if (hasAccessToFeature || !featureName) return <>{children}</>
  return (
    <Box
      onClick={() => {
        onOpen?.()
        dispatch(
          setLockedFeatureModal({
            lockedFeatureName: featureName,
            planType: lockedFeaturePlanType
          })
        )
        lockContent?.trackingCall?.()
      }}
    >
      <Stack
        direction={getFlexDirectionValue(iconPosition)}
        sx={{
          alignItems: 'center',
          pointerEvents: showLockIcon ? 'unset' : 'none',
          ...sx
        }}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        data-testid={'locked-feature-wrapper'}
      >
        {children}
        {showLockIcon &&
          (showTooltip && tooltipContent ? (
            <Tooltip
              id={tooltipContent.id}
              title={
                <TooltipContent
                  title={
                    isInTrial && tooltipContent.trialVariant
                      ? tooltipContent.trialVariant.title
                      : tooltipContent.title
                  }
                  content={
                    isInTrial && tooltipContent.trialVariant
                      ? tooltipContent.trialVariant.body
                      : tooltipContent.body
                  }
                />
              }
              placement={isMobile ? 'bottom' : tooltipContent.placement}
              open={isTooltipOpen}
              arrow
              onOpen={() => {
                fireAnalyticsEvent(`LockedFeatureWrapper_tooltip_${tooltipContent?.id}_open`)
                setIsTooltipOpen(true)
              }}
              onClose={() => setIsTooltipOpen(false)}
              data-testid={'lfw-tooltip'}
            >
              {/* an immediate element is necessary for the tooltip to function */}
              <span>
                <ProTag
                  tagCopy={lockIconCopy || planTypeName}
                  minimalIcon={minimalIcon}
                  cursor="pointer"
                  sx={iconSx}
                />
              </span>
            </Tooltip>
          ) : (
            <ProTag
              tagCopy={lockIconCopy || planTypeName}
              minimalIcon={minimalIcon}
              cursor="pointer"
            />
          ))}
      </Stack>
    </Box>
  )
}
